<template>
  <div class="chatindex">
      <div class="mainbox base_w">
          <div class="tabsbox flexbox"> 
            <p @click.stop="change(1)" :class="[tabItem == 1 ? 'active' : '']">对话聊天</p>
            <p @click.stop="change(2)" :class="[tabItem == 2 ? 'active' : '']">系统通知</p>
          </div>
          <div class="textbox">
              <div class="chat" v-if="tabItem == 1">
                <div class="noticeitem flex chatnotic" 
                :class=" [chatItem == i ? 'chatItem' :''] " 
                v-for="(item,i) in allConversation" :key="i" 
                @click.stop="clickFun(i)"
                @mouseenter="mouseenter(i)" 
                @mouseleave="mouseleave(i)">
                  <div class="leftbox flex">
                      <div class="avabox">
                      <div v-show="item.unreadCount > 0">	{{item.unreadCount > 99?'99+':item.unreadCount}} </div>
                      <img :src="item.userProfile.avatar" alt="" class="avaimg">
                    </div>
                    <div class="textbox1">
                      <p> {{ item.userProfile.nick }}  <span>  {{ item.lastMessage._lastTime }} </span></p>
                      <div v-if="!item.lastMessage.isRevoked">{{ item.lastMessage.messageForShow }} </div>
                    </div>
                  </div>
                  <div class="rightbox" @click.stop="clickallConversation(i)"> x </div>
               
                </div>
              </div>
              <div class="notice" v-if="tabItem == 2">
                <div class="noticeitem flex" v-for="(item,i) in list" :key="i">
                  <img src="@/assets/img/laba.png" alt="" class="laba">
                  <div class="textbox1">
                    <p> {{ item.lawyerId?item.lawyerName:'系统消息通知' }}  <span>  {{item.createTime | fifterTime}} </span></p>
                    <div>{{item.content}}</div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
import resetTime from "@/util/check.js";
import { mapState } from 'vuex'

export default {
  components: {},
  data() {
      return {
        chatItem:-1,
        tabItem: 1, // 1 对话聊天 2 系统通知
        list: [],
        userimID: localStorage.getItem("userimID"), //用户手机号 ，im userid
				userID: localStorage.getItem("userid"), //用户id
				userSig: localStorage.getItem("userSig"),
      };
  },
  filters:{
    // 时间
    fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
  },
  computed: {
    ...mapState({
				allConversation: state => state.allConversation,
		})
    // allConversation() {
    //   return this.$store.state.allConversation;
    // },

  },
  watch: {},
  created() {},
  mounted() {
    this.getlist();
    if (this.userID) {
				this.$tim.login({
					userID: 'u' + this.userimID,
					userSig: this.userSig
				});
		}

    // console.log(this.$store.state.allConversation,'msg聊天页面');


  },
  methods: {
    change(num){
      this.tabItem = num;
      if(num == 2){
        this.getlist();
      }
    },
    // 系统通知
    getlist() {
      if(!localStorage.getItem('userId')){
        return;
      }
      let data = {
        userId:localStorage.getItem('userId')
      }
      apiUrl.lsUserMsglist(data).then((res) => {
        this.list = res.rows;
      })
    },
    // 鼠标进入会话
    mouseenter(index){
      this.chatItem = index;
    },
    // 鼠标离开会话
    mouseleave(){
      this.chatItem = -1;
    },
    // 点击会话
    clickFun(index){
      let phone = this.allConversation[index].toAccount.slice(1, 13);
      
      let path = this.$router.resolve({
        path: '/chat?lawyerphone=' + phone
      });
       // 打开一个新的页面     
      window.open(path.href, '_blank');

      this.$tim.setMessageRead({
        conversationID: 'C2C' + this.allConversation[index].toAccount
      });
    },

    // 删除
    clickallConversation(index){
      let that = this;
      this.$confirm('是否删除该消息?', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
        // 删除
        let promise = that.$tim.deleteConversation(that.allConversation[index].conversationID);
        promise.then(function(imResponse) {
          //删除成功。
          const {
            conversationID
          } = imResponse.data; // 被删除的会话 ID
        }).catch(function(imError) {
          console.warn('deleteConversation error:', imError); // 删除会话失败的相关信息
        });

			
			}).catch(() => {
			  this.$message({
          type: 'info',
          message: '已取消删除'
			  });          
			});
    }


  },
}
</script>
<style lang='scss' scoped>
.chatindex{
  background-color:  #F8F8F8;
  padding: 20px 0 33px;
  .mainbox{
    // min-height: 650px;
    // overflow: auto;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 17px 0 84px;
    .tabsbox{
      height: 40px;
      line-height: 41px;
      border-bottom: 1px solid  #ECECEC;
      padding-left: 67px;
      p{
        font-size: 15px;
        color: #333333;
        margin-right: 53px;
        cursor: pointer;
      }
      .active{
          font-weight: 600;
          color: #305BFE;
          border-bottom: 3px solid #305BFE;
      }
    }
    .textbox{
      // padding: 0 67px;
      // box-sizing: border-box;
    }
  }

  // 系统通知
  .notice{
    padding: 0 67px;
    box-sizing: border-box;
  }
  .noticeitem{
    padding: 27px 0;
    border-bottom: 1px solid #ECECEC;
    img{
      width: 47px;
      height: 47px;
      margin-right: 17px;
    }
    .textbox1{
     
      p{
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        span{
          font-size: 12px;
          color: #999999;
          margin-left: 37px;
        }
      }
      div{
         margin-top: 8px;
          font-size: 13px;
          color: #666666;
          line-height: 18px;
      }
    }
  }

  .chat{
    // padding: 0 67px;
    box-sizing: border-box;
  }
  .chatnotic{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 67px;
    padding-right: 67px;
    .rightbox{
      font-size: 25px;
      display: none;
      width:50px;
    }
  }

  .chatItem{
    background: #F5F5F5;
    .rightbox{
      display: block;
      cursor: pointer;
    }
  }

  .avabox{
    position: relative;
    div{
      position: absolute;
      top: -4px;
      right: 12px;

      width: 17px;
      height: 17px;
      line-height: 17px;
      background: #F34933;
      border-radius: 50%;
      color: white;
      text-align: center;
      font-size: 12px;
    }
    .avaimg{
      width: 47px;
      height: 47px;
      border-radius: 50%;
      margin-right: 17px;
    }
  }
  
}
</style>